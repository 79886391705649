import React from "react";
import { useTranslation } from "react-i18next";
import ReactPlayer from "react-player";
import styled from "styled-components";
import heroVideoMobile from "../assets/heroVideo-mobile.m4v";
import heroVideo from "../assets/heroVideo.m4v";
import { device } from "../breakpoints";
import SignUpForm from "./customers/SignUpForm.jsx";
import CountdownTimer from "./CountdownTimer";

const Hero = () => {
  const isMobile = () =>
    window.matchMedia && window.matchMedia("(max-width: 480px)").matches;
  const { t } = useTranslation();

  const scrollToFeatures = () => {
    const target = document.getElementById("features");
    const navbarHeight = document.querySelector("nav").offsetHeight - 20; // Get the height of the navbar
    const targetPosition = target.getBoundingClientRect().top + window.scrollY;
    const offsetPosition = targetPosition - navbarHeight;
    const startPosition = window.scrollY;
    const distance = offsetPosition - startPosition;
    const duration = 1500; // Duration in milliseconds
    let start = null;

    window.requestAnimationFrame(step);

    function step(timestamp) {
      if (!start) start = timestamp;
      const progress = timestamp - start;
      const progressPercent = Math.min(progress / duration, 1);
      window.scrollTo(
        0,
        startPosition + distance * easeInOutCubic(progressPercent)
      );
      if (progress < duration) {
        window.requestAnimationFrame(step);
      }
    }

    // Easing function for smooth scrolling
    function easeInOutCubic(t) {
      return t < 0.5 ? 4 * t * t * t : 1 - Math.pow(-2 * t + 2, 3) / 2;
    }
  };

  return (
    <Section>
      <HeroVideo>
        <ReactPlayer
          url={isMobile() ? heroVideoMobile : heroVideo}
          playing
          loop
          muted
          width="100%"
          height="100%"
          playsinline
        />
      </HeroVideo>
      <Overlay>
        <Content>
          <Title>{t("hero_section.hero_title")}</Title>
          <Subtitle>{t("hero_section.hero_subtitle_founder")}</Subtitle>
          <CountdownWrapper>
            <CountdownTimer targetDate="2024-12-31T00:00:00" />
          </CountdownWrapper>
          <FormWrapper>
            <SignUpForm
              inputFocusClass="top"
              buttonColor="#e9e928"
              textColor="white"
              borderColor="#939182"
              colorr="#F02C2C"
              submitButtonColor="#E9E928"
              submitButtonHoverColor="#E9E928" // Hover color
              focusBorderColor="#E9E928" // Custom focus border color
              buttonTextColor="black"
            />
          </FormWrapper>
        </Content>
      </Overlay>
      <ScrollIndicator onClick={scrollToFeatures}>
        <Arrow>▼</Arrow>
      </ScrollIndicator>
    </Section>
  );
};

export default Hero;

const Section = styled.section`
  position: relative;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #14140f;
`;

const HeroVideo = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;

  video {
    object-fit: cover;
  }
`;

const Overlay = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
`;

const Content = styled.div`
  text-align: center;
  color: white;
  padding: 20px;
  /* margin-top: 56px; */
  @media (max-height: 800px) {
    margin-top: 3.5rem;
  }
`;

const Title = styled.h1`
  font-size: 2rem;
  line-height: 1.2em;
  max-width: 300px;
  margin: 0 auto;
  margin-bottom: 1rem;

  :lang(he) {
    max-width: 400px;
  }

  @media ${device.pc} {
    font-size: 3.5rem;
    max-width: none;
    margin-bottom: 2rem;

    :lang(he) {
      max-width: none;
    }
  }
`;

const Subtitle = styled.h4`
  font-size: 1rem;
  line-height: 1.4em;
  max-width: 275px;
  margin: 0 auto;
  font-weight: 400;

  @media ${device.pc} {
    font-size: 1.4rem;
    max-width: 650px;
    margin-bottom: 3rem;
    margin-top: 2rem;
  }
`;

const CountdownWrapper = styled.div`
  margin-bottom: 1rem;

  @media ${device.pc} {
    margin-bottom: 2rem;
  }
`;

const FormWrapper = styled.div`
  text-align: ${(props) =>
    props.dir === "rtl"
      ? "left"
      : "right"}; /* Override text-align for the form */
  width: 100%; /* Ensure it does not exceed the viewport width */
  padding: 0 10px;
  /* box-sizing: border-box; */

  @media ${device.pc} {
    padding: 0;
  }
`;

const ScrollIndicator = styled.div`
  z-index: 1;
  position: absolute;
  bottom: 10px;
  display: flex;
  justify-content: center;
  cursor: pointer; /* Indicate that it's clickable */
`;

const Arrow = styled.div`
  font-size: 2rem;
  color: yellow;
  animation: bounce 2s infinite;

  @keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-10px);
    }
    60% {
      transform: translateY(-5px);
    }
  }
`;
